import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

import Modal from "./components/modal";
import { Button } from "./components/modal/modal.styles";

var crypto = require("crypto");


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


export const StyledButton = styled.button`
  padding: 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--primary);
  padding: 20px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton2 = styled.a`
  padding: 30px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 30px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--primary-text);
  width: 400px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton3 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton4 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--accent);
  padding: 10px;
  font-family: 'Press Start 2P';
  color: var(--new-text);
  width: 300px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 1000px;
  min-height: 100px;
  max-height: 1000px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledImg2 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 128px;
  min-height: 100px;
  max-height: 192px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledImg3 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 100px;
  max-width: 256px;
  min-height: 100px;
  max-height: 384px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  padding: 10px;
  font-family: 'Press Start 2P';
  color: var(--accent);
  text-decoration: none;
`;



function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`You are opening One pack. Once you open, you cannot undo or redo.`);
  const [mintAmount, setMintAmount] = useState(1);

  // open packs
  const [newCards, setNewCards] = useState({});
  const [newCardsPack, setNewCardsPack] = useState(0);

  // json
  const [file, setFile] = useState(4);
  const [card, setCard] = useState({});
  const [cardAtt, setAtt] = useState({});
  const [cardDef, setDef] = useState({});
  const [cardSiz, setSiz] = useState({});
  const [cardTyp, setTyp] = useState({});
  const [cardSra, setSra] = useState({});
  const [cardAra, setAra] = useState({});
  const [cardAbi, setAbi] = useState({});
  const [cardSpe, setSpe] = useState({});
  const [cardCty, setCty] = useState({});

  useEffect(() => {
    fetch("/config/json/" + file, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    }).then(response => response.json())
      .then(data => {
        setCard(data);
        setAtt(data.attributes[0]);
        setDef(data.attributes[1]);
        setSiz(data.attributes[2]);
        setTyp(data.attributes[3]);
        setSra(data.attributes[4]);
        setAra(data.attributes[5]);
        setAbi(data.attributes[6]);
        setSpe(data.attributes[7]);
        setCty(data.attributes[8])
      })
      .catch(error => console.error(error));
  }, [file]);

  // modal
  const [active, setActive] = useState(false);

  // modal for pack opening
  const [active2, setActive2] = useState(false);


  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },

    NFT_NAME_MINI: "",
    SYMBOL_MINI: "",
    GAS_LIMIT_MINI: 0,

    NFT_NAME_STANDARD: "",
    SYMBOL_STANDARD: "",
    GAS_LIMIT_STANDARD: 0,

    NFT_NAME_COLLECTOR: "",
    SYMBOL_COLLECTOR: "",
    GAS_LIMIT_COLLECTOR: 0,

    NFT_NAME_PROMO: "",
    SYMBOL_PROMO: "",
    GAS_LIMIT_PROMO: 0,

    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  function getMiniMinterHash() {
    const hashArray = [];
    hashArray[0] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[1] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[2] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[3] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[4] = crypto.randomBytes(32).toString('hex').toString();
    var arrayAsString = JSON.stringify(hashArray);
    return hashArray;
  }

  function getStandardMinterHash() {
    const hashArray = [];
    hashArray[0] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[1] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[2] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[3] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[4] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[5] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[6] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[7] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[8] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[9] = crypto.randomBytes(32).toString('hex').toString();
    var arrayAsString = JSON.stringify(hashArray);
    return hashArray;
  }

  function getCollectorMinterHash() {
    const hashArray = [];
    hashArray[0] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[1] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[2] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[3] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[4] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[5] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[6] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[7] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[8] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[9] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[10] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[11] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[12] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[13] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[14] = crypto.randomBytes(32).toString('hex').toString();
    var arrayAsString = JSON.stringify(hashArray);
    return hashArray;
  }

  function getPromoMinterHash() {
    const hashArray = [];
    hashArray[0] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[1] = crypto.randomBytes(32).toString('hex').toString();
    hashArray[2] = crypto.randomBytes(32).toString('hex').toString();
    var arrayAsString = JSON.stringify(hashArray);
    return hashArray;
  }

  function getShuffleHash() {
    var randShuffleHash = crypto.randomBytes(32).toString('hex');
    return randShuffleHash;
  }

  const openMini = () => {
    let gasLimit = CONFIG.GAS_LIMIT_MINI;
    let totalGasLimit = String(gasLimit * mintAmount);
    let miniMinterHash = getMiniMinterHash();
    let shuffleHash = getShuffleHash();
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Opening your ${CONFIG.NFT_NAME_MINI}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .openMiniPack(miniMinterHash, shuffleHash)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `A ${CONFIG.NFT_NAME_MINI} was opened!`
        );


        setActive2(true);
        let eventArray = receipt.events.TransferSingle;
        let newCardJs = {};
        for (let i = 1; i < eventArray.length; i++) {
          newCardJs[i] = parseInt(eventArray[i].returnValues.id);
        };
        setNewCardsPack(1);
        setNewCards(newCardJs);


        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const openStandard = () => {
    let gasLimit = CONFIG.GAS_LIMIT_STANDARD;
    let totalGasLimit = String(gasLimit * mintAmount);
    let standardMinterHash = getStandardMinterHash();
    let shuffleHash = getShuffleHash();
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Opening your ${CONFIG.NFT_NAME_STANDARD}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .openStandardPack(standardMinterHash, shuffleHash)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `A ${CONFIG.NFT_NAME_STANDARD} was opened!`
        );


        setActive2(true);
        let eventArray = receipt.events.TransferSingle;
        let newCardJs = {};
        for (let i = 1; i < eventArray.length; i++) {
          newCardJs[i] = parseInt(eventArray[i].returnValues.id);
        };
        setNewCardsPack(2);
        setNewCards(newCardJs);


        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const openCollector = () => {
    let gasLimit = CONFIG.GAS_LIMIT_COLLECTOR;
    let totalGasLimit = String(gasLimit * mintAmount);
    let collectorMinterHash = getCollectorMinterHash();
    let shuffleHash = getShuffleHash();
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME_COLLECTOR}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .openCollectorPack(collectorMinterHash, shuffleHash)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `A ${CONFIG.NFT_NAME_COLLECTOR} was opened!`
        );


        setActive2(true);
        let eventArray = receipt.events.TransferSingle;
        let newCardJs = {};
        for (let i = 1; i < eventArray.length; i++) {
          newCardJs[i] = parseInt(eventArray[i].returnValues.id);
        };
        setNewCardsPack(3);
        setNewCards(newCardJs);


        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const openPromo = () => {
    let gasLimit = CONFIG.GAS_LIMIT_COLLECTOR;
    let totalGasLimit = String(gasLimit * mintAmount);
    let promoMinterHash = getPromoMinterHash();
    let shuffleHash = getShuffleHash();
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME_COLLECTOR}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .openPromoPack(promoMinterHash, shuffleHash)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Transaction error. Check your connected wallet, or try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `A ${CONFIG.NFT_NAME_COLLECTOR} was opened!`
        );


        setActive2(true);
        let eventArray = receipt.events.TransferSingle;
        let newCardJs = {};
        for (let i = 1; i < eventArray.length; i++) {
          newCardJs[i] = parseInt(eventArray[i].returnValues.id);
        };
        setNewCardsPack(0);
        setNewCards(newCardJs);


        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount;
    if (newMintAmount > 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "" : null}
      >



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Your SGB Craft Collection
            </s.MainTitle>

            <s.ContractAddress
              style={{
                textAlign: "center",
                color: "var(--primary)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.ContractAddress>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledButton4
                  href="#mini"
                  style={{
                    fontSize: 24,
                    lineHeight: 1.6,
                    textAlign: "center",
                    textDecoration: 'none',
                  }}
                >
                  Open A<br></br>Mini Pack
                </StyledButton4>

              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledButton4
                  href="#standard"
                  style={{
                    fontSize: 24,
                    lineHeight: 1.6,
                    textAlign: "center",
                    textDecoration: 'none',
                  }}
                >
                  Open A<br></br>Standard
                </StyledButton4>
              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledButton4
                  href="#collector"
                  style={{
                    fontSize: 24,
                    lineHeight: 1.6,
                    textAlign: "center",
                    textDecoration: 'none',
                  }}
                >
                  Open A<br></br>Collector
                </StyledButton4>
              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledButton4
                  href="#promo"
                  style={{
                    fontSize: 24,
                    lineHeight: 1.6,
                    textAlign: "center",
                    textDecoration: 'none',
                  }}
                >
                  Open A<br></br>Promo Pack
                </StyledButton4>
              </s.Container>

            </ResponsiveWrapper>

            <StyledButton2
              href="https://sgbcraftminter.coinylabs.com"
              style={{
                fontSize: 30,
                lineHeight: 1.6,
                textAlign: "center",
                textDecoration: 'none',
              }}
            >
              Buy More Packs
            </StyledButton2>

            <s.SpacerSmall />

            <StyledButton2
              href="#collection"
              style={{
                fontSize: 30,
                lineHeight: 1.6,
                backgroundColor: "blue",
                textAlign: "center",
                textDecoration: 'none',
              }}
            >
              View Collection
            </StyledButton2>


          </s.Container>

        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>

            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Packs
            </s.MainTitle>

            <s.SpacerSmall />

            <s.TextDescription style={{ textAlign: "center", color: "var(--new-text)", }}>
              Please make sure you are connected to the right network (
              {CONFIG.NETWORK.NAME} Mainnet).
              <br></br>
              We use {CONFIG.GAS_LIMIT_MINI} gas limit to successfully open pack.
            </s.TextDescription>

            <s.SpacerSmall />

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>

                <StyledImg2 src={"/config/images/minipack.png"} />

                <s.SpacerSmall />

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--new-text)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"mini"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[1]}<br></br>Mini<br></br>Packs
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[1]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no {CONFIG.NFT_NAME_MINI}.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                openMini();
                                getData();
                              }}
                            >
                              {claimingNft ? "OPENING..." : "OPEN NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                  <StyledButton3
                    href="https://sgbcraftminter.coinylabs.com"
                    style={{
                      fontSize: 24,
                      lineHeight: 1.6,
                      textAlign: "center",
                      textDecoration: 'none',
                    }}
                  >
                    Buy Packs
                  </StyledButton3>

                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledImg2 src={"/config/images/standardpack.png"} />

                <s.SpacerSmall />

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--new-text)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"standard"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[2]}<br></br>Standard<br></br>Packs
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[2]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no {CONFIG.NFT_NAME_STANDARD}.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                openStandard();
                                getData();
                              }}
                            >
                              {claimingNft ? "OPENING..." : "OPEN NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                  <StyledButton3
                    href="https://sgbcraftminter.coinylabs.com"
                    style={{
                      fontSize: 24,
                      lineHeight: 1.6,
                      textAlign: "center",
                      textDecoration: 'none',
                    }}
                  >
                    Buy Packs
                  </StyledButton3>

                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledImg2 src={"/config/images/collectorpack.png"} />

                <s.SpacerSmall />

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--new-text)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"collector"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[3]}<br></br>Collector<br></br>Packs
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[3]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no {CONFIG.NFT_NAME_COLLECTOR}.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                openCollector();
                                getData();
                              }}
                            >
                              {claimingNft ? "OPENING..." : "OPEN NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                  <StyledButton3
                    href="https://sgbcraftminter.coinylabs.com"
                    style={{
                      fontSize: 24,
                      lineHeight: 1.6,
                      textAlign: "center",
                      textDecoration: 'none',
                    }}
                  >
                    Buy Packs
                  </StyledButton3>

                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledImg2 src={"/config/images/promopack.png"} />

                <s.SpacerSmall />

                <s.Container flex={1} jc={"top"} ai={"center"}
                  style={{
                    backgroundColor: "var(--new-text)",
                    padding: 15,
                    borderRadius: 24,
                    border: "4px  var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                  id={"promo"}
                >

                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
                    {data.totalSupply[0]}<br></br>Promo<br></br>Packs
                  </s.TextTitle>

                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >

                  </span>
                  <s.SpacerXSmall />
                  {Number(data.totalSupply[0]) < 1 ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You have no {CONFIG.NFT_NAME_PROMO}.
                      </s.TextTitle>

                    </>
                  ) : (
                    <>

                      <s.SpacerXSmall />

                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.Container ai={"center"} jc={"center"}>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Connect to the {CONFIG.NETWORK.NAME} network
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <StyledButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(connect());
                              getData();
                            }}
                          >
                            CONNECT SONGBIRD
                          </StyledButton>
                          {blockchain.errorMsg !== "" ? (
                            <>
                              <s.SpacerSmall />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {blockchain.errorMsg}
                              </s.TextDescription>
                            </>
                          ) : null}
                        </s.Container>
                      ) : (
                        <>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                openPromo();
                                getData();
                              }}
                            >
                              {claimingNft ? "OPENING..." : "OPEN NOW"}
                            </StyledButton>

                          </s.Container>
                        </>
                      )}
                    </>
                  )}
                  <s.SpacerSmall />

                  <StyledButton3
                    href="https://sgbcraftminter.coinylabs.com"
                    style={{
                      fontSize: 24,
                      lineHeight: 1.6,
                      textAlign: "center",
                      textDecoration: 'none',
                    }}
                  >
                    Buy Packs
                  </StyledButton3>

                  <s.SpacerSmall />

                </s.Container>

              </s.Container>

            </ResponsiveWrapper>
            <s.SpacerSmall />
          </s.Container>
        </ResponsiveWrapper>



        <Modal
          active={active2}
          hideModal={() => setActive2(false)}
          title="And your new cards are..."
          footer={<Button onClick={(e) => { setActive2(false) }}>Close</Button>}
        >
          <s.Container style={{ padding: 20, }} flex={1} jc={"center"} ai={"center"}>

            {newCardsPack === 1 ? (<>
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                Common Slot
                <s.SpacerXSmall />
                <StyledImg2 src={"/config/cardimages/" + newCards[1]} onClick={(e) => { setActive(true); setFile(newCards[1]) }} />
                <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                  <s.SpacerSmall />
                  {data.totalSupply[newCards[1]]} in Collection
                </s.TextTitle>
              </s.Container>
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                Uncommon Slot (C or U)
                <s.SpacerXSmall />
                <StyledImg2 src={"/config/cardimages/" + newCards[2]} onClick={(e) => { setActive(true); setFile(newCards[2]) }} />
                <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                  <s.SpacerSmall />
                  {data.totalSupply[newCards[2]]} in Collection
                </s.TextTitle>
              </s.Container>
              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                Rare Slot (U or R)
                <s.SpacerXSmall />
                <StyledImg2 src={"/config/cardimages/" + newCards[3]} onClick={(e) => { setActive(true); setFile(newCards[3]) }} />
                <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                  <s.SpacerSmall />
                  {data.totalSupply[newCards[3]]} in Collection
                </s.TextTitle>
              </s.Container>
            </>) : (<>

              {newCardsPack === 2 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  Common Card
                  <s.SpacerXSmall />
                  <StyledImg2 src={"/config/cardimages/" + newCards[1]} onClick={(e) => { setActive(true); setFile(newCards[1]) }} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                    <s.SpacerSmall />
                    {data.totalSupply[newCards[1]]} in Collection
                  </s.TextTitle>
                </s.Container>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  Uncommon Card
                  <s.SpacerXSmall />
                  <StyledImg2 src={"/config/cardimages/" + newCards[2]} onClick={(e) => { setActive(true); setFile(newCards[2]) }} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                    <s.SpacerSmall />
                    {data.totalSupply[newCards[2]]} in Collection
                  </s.TextTitle>
                </s.Container>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  Uncommon Slot (C or U)
                  <s.SpacerXSmall />
                  <StyledImg2 src={"/config/cardimages/" + newCards[3]} onClick={(e) => { setActive(true); setFile(newCards[3]) }} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                    <s.SpacerSmall />
                    {data.totalSupply[newCards[3]]} in Collection
                  </s.TextTitle>
                </s.Container>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  Uncommon Slot (C or U)
                  <s.SpacerXSmall />
                  <StyledImg2 src={"/config/cardimages/" + newCards[4]} onClick={(e) => { setActive(true); setFile(newCards[4]) }} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                    <s.SpacerSmall />
                    {data.totalSupply[newCards[4]]} in Collection
                  </s.TextTitle>
                </s.Container>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  Rare Slot (U or R)
                  <s.SpacerXSmall />
                  <StyledImg2 src={"/config/cardimages/" + newCards[5]} onClick={(e) => { setActive(true); setFile(newCards[5]) }} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                    <s.SpacerSmall />
                    {data.totalSupply[newCards[5]]} in Collection
                  </s.TextTitle>
                </s.Container>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  Legendary Slot (R or L)
                  <s.SpacerXSmall />
                  <StyledImg2 src={"/config/cardimages/" + newCards[6]} onClick={(e) => { setActive(true); setFile(newCards[6]) }} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                    <s.SpacerSmall />
                    {data.totalSupply[newCards[6]]} in Collection
                  </s.TextTitle>
                </s.Container>
              </>) : (<>

                {newCardsPack === 3 ? (<>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Common Card (Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[1]} onClick={(e) => { setActive(true); setFile(newCards[1]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[1]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Uncommon Card (Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[2]} onClick={(e) => { setActive(true); setFile(newCards[2]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[2]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Rare Card (Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[3]} onClick={(e) => { setActive(true); setFile(newCards[3]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[3]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Uncommon Slot (C or U, Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[4]} onClick={(e) => { setActive(true); setFile(newCards[4]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[4]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Uncommon Slot (C or U, Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[5]} onClick={(e) => { setActive(true); setFile(newCards[5]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[5]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Uncommon Slot (C or U, Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[6]} onClick={(e) => { setActive(true); setFile(newCards[6]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[6]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Rare Slot (U or R, Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[7]} onClick={(e) => { setActive(true); setFile(newCards[7]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[7]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Rare Slot (U or R, Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[8]} onClick={(e) => { setActive(true); setFile(newCards[8]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[8]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                  <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                    Legendary Slot (R or L, Animated or Collector)
                    <s.SpacerXSmall />
                    <StyledImg2 src={"/config/cardimages/" + newCards[9]} onClick={(e) => { setActive(true); setFile(newCards[9]) }} />
                    <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                      <s.SpacerSmall />
                      {data.totalSupply[newCards[9]]} in Collection
                    </s.TextTitle>
                  </s.Container>
                </>) : (<>

                  {newCardsPack === 0 ? (<>
                    <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                      Wild Slot (Collector)
                      <s.SpacerXSmall />
                      <StyledImg2 src={"/config/cardimages/" + newCards[1]} onClick={(e) => { setActive(true); setFile(newCards[1]) }} />
                      <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                        <s.SpacerSmall />
                        {data.totalSupply[newCards[1]]} in Collection
                      </s.TextTitle>
                    </s.Container>
                    <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                      Wild Slot (Collector)
                      <s.SpacerXSmall />
                      <StyledImg2 src={"/config/cardimages/" + newCards[2]} onClick={(e) => { setActive(true); setFile(newCards[2]) }} />
                      <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                        <s.SpacerSmall />
                        {data.totalSupply[newCards[2]]} in Collection
                      </s.TextTitle>
                    </s.Container>
                    <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                      Promo Slot
                      <s.SpacerXSmall />
                      <StyledImg2 src={"/config/cardimages/" + newCards[3]} onClick={(e) => { setActive(true); setFile(newCards[3]) }} />
                      <s.TextTitle style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", color: "var(--new-text)", }} >
                        <s.SpacerSmall />
                        {data.totalSupply[newCards[3]]} in Collection
                      </s.TextTitle>
                    </s.Container>
                  </>) : (<> </>)}

                </>)}

              </>)}

            </>)}

          </s.Container>
        </Modal>



        <Modal
          active={active}
          hideModal={() => setActive(false)}
          title={card.name}
          footer={<Button onClick={(e) => { setActive(false) }}>Close</Button>}
        >
          <s.Container style={{ padding: 20, }} flex={1} jc={"center"} ai={"center"}>
            <StyledImg3 src={"/config/cardimages/" + file} />
            <br></br><br></br>
            {card.description}
            <br></br><br></br>
            Edition: {card.type}
            <br></br><br></br>
            Card Rarity: {cardCty.value}
            <br></br><br></br>
            Card Type: {cardTyp.value}
            <br></br><br></br>
            Attack: {cardAtt.value}
            <br></br><br></br>
            Defence: {cardDef.value}
            <br></br><br></br>
            Size: {cardSiz.value}
            <br></br><br></br>
            Surface Range: {cardSra.value}
            <br></br><br></br>
            Air Range: {cardAra.value}
            <br></br><br></br>
            Ability: {cardAbi.value}
            <br></br><br></br>
            Special: {cardSpe.value}
            <br></br><br></br>
          </s.Container>
        </Modal>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"} id={"collection"} >

            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Your Collection
            </s.MainTitle>

            <s.SpacerXSmall />

            <s.TextDescription style={{ textAlign: "center", color: "var(--new-text)", }}>
              Click on cards to learn more!
            </s.TextDescription>

            <s.SpacerSmall />

          </s.Container>
        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Legendary Cards
            </s.MainTitle>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[43]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 43} onClick={(e) => { setActive(true); setFile(43) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[43]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[44]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 44} onClick={(e) => { setActive(true); setFile(44) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[44]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[45]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 45} onClick={(e) => { setActive(true); setFile(45) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[45]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[40]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 40} onClick={(e) => { setActive(true); setFile(40) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[40]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<>  </>)}

              {Number(data.totalSupply[41]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 41} onClick={(e) => { setActive(true); setFile(41) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[41]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[42]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 42} onClick={(e) => { setActive(true); setFile(42) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[42]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<>   </>)}

            </ResponsiveWrapper>

            <s.SpacerSmall />
          </s.Container>
        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Rare Cards
            </s.MainTitle>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[31]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 31} onClick={(e) => { setActive(true); setFile(31) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[31]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[32]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 32} onClick={(e) => { setActive(true); setFile(32) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[32]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[33]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 33} onClick={(e) => { setActive(true); setFile(33) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[33]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[34]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 34} onClick={(e) => { setActive(true); setFile(34) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[34]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[35]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 35} onClick={(e) => { setActive(true); setFile(35) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[35]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[36]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 36} onClick={(e) => { setActive(true); setFile(36) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[36]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[37]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 37} onClick={(e) => { setActive(true); setFile(37) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[37]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[38]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 38} onClick={(e) => { setActive(true); setFile(38) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[38]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[39]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 39} onClick={(e) => { setActive(true); setFile(39) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[39]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <s.SpacerSmall />
          </s.Container>
        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>

            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Uncommon Cards
            </s.MainTitle>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[19]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 19} onClick={(e) => { setActive(true); setFile(19) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[19]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[20]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 20} onClick={(e) => { setActive(true); setFile(20) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[20]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[21]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 21} onClick={(e) => { setActive(true); setFile(21) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[21]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[22]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 22} onClick={(e) => { setActive(true); setFile(22) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[22]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[23]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 23} onClick={(e) => { setActive(true); setFile(23) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[23]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[24]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 24} onClick={(e) => { setActive(true); setFile(24) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[24]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[25]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 25} onClick={(e) => { setActive(true); setFile(25) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[25]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[26]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 26} onClick={(e) => { setActive(true); setFile(26) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[26]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[27]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 27} onClick={(e) => { setActive(true); setFile(27) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[27]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[28]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 28} onClick={(e) => { setActive(true); setFile(28) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[28]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[29]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 29} onClick={(e) => { setActive(true); setFile(29) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[29]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[30]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 30} onClick={(e) => { setActive(true); setFile(30) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[30]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>
            <s.SpacerSmall />
          </s.Container>
        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>

            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Common Cards
            </s.MainTitle>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[4]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 4} onClick={(e) => { setActive(true); setFile(4) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[4]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[5]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 5} onClick={(e) => { setActive(true); setFile(5) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[5]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[6]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 6} onClick={(e) => { setActive(true); setFile(6) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[6]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[7]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 7} onClick={(e) => { setActive(true); setFile(7) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[7]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[8]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 8} onClick={(e) => { setActive(true); setFile(8) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[8]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[9]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 9} onClick={(e) => { setActive(true); setFile(9) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[9]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[10]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 10} onClick={(e) => { setActive(true); setFile(10) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[10]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[11]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 11} onClick={(e) => { setActive(true); setFile(11) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[11]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[12]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 12} onClick={(e) => { setActive(true); setFile(12) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[12]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[13]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 13} onClick={(e) => { setActive(true); setFile(13) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[13]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[14]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 14} onClick={(e) => { setActive(true); setFile(14) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[14]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[15]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 15} onClick={(e) => { setActive(true); setFile(15) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[15]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              {Number(data.totalSupply[16]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 16} onClick={(e) => { setActive(true); setFile(16) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[16]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[17]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 17} onClick={(e) => { setActive(true); setFile(17) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[17]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

              {Number(data.totalSupply[18]) > 0 ? (<>
                <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                  <StyledImg2 src={"/config/cardimages/" + 18} onClick={(e) => { setActive(true); setFile(18) }} />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "var(--new-text)",
                    }}
                  >
                    <s.SpacerSmall />
                    {data.totalSupply[18]}
                  </s.TextTitle>
                </s.Container>
              </>) : (<> </>)}

            </ResponsiveWrapper>

            <s.SpacerSmall />

          </s.Container>
        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.SpacerSmall />

            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Tokens
            </s.MainTitle>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledImg2 src={"/config/images/playertoken.png"} />
                <s.SpacerSmall />
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "var(--new-text)",
                  }}
                >
                  {data.totalSupply[49]}
                </s.TextTitle>

              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledImg2 src={"/config/images/winnertoken.png"} />
                <s.SpacerSmall />
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "var(--new-text)",
                  }}
                >
                  {data.totalSupply[50]}
                </s.TextTitle>

              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledImg2 src={"/config/images/votertoken.png"} />
                <s.SpacerSmall />
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "var(--new-text)",
                  }}
                >
                  {data.totalSupply[51]}
                </s.TextTitle>

              </s.Container>

            </ResponsiveWrapper>

            <s.SpacerSmall />
          </s.Container>
        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>





        </ResponsiveWrapper>

        <s.SpacerMedium />

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>

          <s.SpacerSmall />

        </s.Container>

      </s.Container>
    </s.Screen>
  );
}

export default App;