// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let currentAddress = await store.getState().blockchain.account;

      let nftAddresses = [];
      for (let i = 0; i < 52; i++) {
        nftAddresses[i] = currentAddress;
      }

      let nftTokenIds = [];
      for (let ii = 0; ii < 52; ii++) {
        nftTokenIds[ii] = ii;
      }

      let totalSupply = await store.getState().blockchain.smartContract.methods.balanceOfBatch(nftAddresses, nftTokenIds).call();
      
      dispatch(
        fetchDataSuccess({
          totalSupply,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};